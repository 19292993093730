//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
   data() {
    return {
      list: [],
      loading: false,
      finished: false,
      monthAgo:0,
      total:99,
      pager: {
        pageIndex: 1,
        pageSize: 10,
        rowsCount: 99,
        start: 0
      }
    };
  },
  created() {
  },
  methods: {
    getDateTime() {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth()
    let startTime = new Date(year, month - this.monthAgo, 1).valueOf();
    let endTime = new Date(year, month  - this.monthAgo + 1, 0).valueOf();
    return {
      b_time:this.timestampToTime(startTime) + ' 00:00:00',
      e_time:this.timestampToTime(endTime)+ ' 23:59:59',
    }
  },
  timestampToTime(timestamp) {
      var date =new Date(timestamp);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
      var Y = date.getFullYear() +'-';
      var M = (date.getMonth() +1 <10 ?'0' + (date.getMonth() +1) : date.getMonth() +1) +'-';
      var D = (date.getDate() +1 <10 ?'0' + date.getDate() : date.getDate());
      return Y +M +D;
  },
  getData() {
    //如果后台卡券中心不设置，看不到核销记录
      let postData = {
        "pager": this.pager,
        "queryMap": {
        },
        "queryMapObject": {}
      }
      this.axios
        .post(
          "/" +
            this.$ajaxPrefix.consumer +
            "/v1/wx/cp/crmcoupon/list",postData
        )
        .then((res) => {
          // this.$toast.clear();
          console.log(res)
          if (res.data.code == 0) {
            res.data.data.list.map(v =>{
              v.time = v.createdAt && v.createdAt.substr(0,10) || ''
            })
            this.list=this.list.concat(res.data.data.list)
            // 加载状态结束
            this.loading = false;
            this.pager.pageIndex += 1;
            this.pager.rowsCount = res.data.data.page.rowsCount
            // 数据全部加载完成
            if (this.pager.rowsCount <= this.list.length) {
              this.finished = true;
            }
          } else {
            if(res.data.msg){
              this.$toast({ message: res.data.msg, duration: 2000 });
            }
            this.loading = false;
            this.finished = true;
          }

        });
    },
  },
  mounted() {
    document.title = "卡券核销记录";
  },
};
